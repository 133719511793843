// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap'

import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import LogoHeadr from '../components/LogoHeadr'

const Thanks = () => {
  const [name, setname] = useState('')
  const [message, setmessage] = useState('')

  useEffect(() => {
    const usern = localStorage.getItem('user')
    const messa = localStorage.getItem('message')
    setmessage(messa)
    setname(usern)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear()
    }, 3000);
  }, [])

  const openAppleStore = () => {
    if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/beyouid/id1544870510';
    }
  }
  const openPlayStore = () => {
    if (isAndroid) {
      window.location.href = "market://details?id=com.beyouid.app";
    }
  }
  const learnMore = () => {
    window.location.href = "https://beyouid.com";
  }

  return (

    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <Card className='mb-0'>
          <CardBody>
            <LogoHeadr />
            <div className='heading mb-1'>
              Thank You!
            </div>
            <CardText className=''>
              Thank you on behalf of {name} and BeYouID team. {message == 'permissions' ? "Your request has been sent." : "Your message has been sent to " + name + "."}
            </CardText>
            <div className='d-flex flex-column align-items-center my-5' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CardText className='cursor-pointer' onClick={openAppleStore}>
                <img src={'images/app-store-apple.png'} width={150} alt='Not authorized page' />
              </CardText>
            </div>
            <div className='d-flex flex-column align-items-center my-5' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CardText className='cursor-pointer' onClick={openPlayStore}>
                <img src={'images/app-store-google.png'} width={150} alt='Not authorized page' />
              </CardText>
            </div>

            <CardText className='mt-5 text-center'>
              Want to learn more about BeYouID?
            </CardText>
            <button className="btn" onClick={learnMore}>
              <span className="title" style={{ fontSize: 16, color: 'white' }}>Learn More</span>
            </button>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Thanks
