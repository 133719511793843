// ** React Imports
import { useState, useCallback, useEffect } from 'react'

// ** React Imports
import { useHistory } from 'react-router-dom'

// ** Reactstrap Imports
import { Card, CardBody, Input } from 'reactstrap'
import ComponentSpinner from '../components/ComponentSpinner'
import LogoHeadr from '../components/LogoHeadr'

const LeaveMessageln = () => {
  //  hook
  const history = useHistory()
  // ** State
  const [content, setContent] = useState('')
  const [type, setType] = useState("")
  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 500))
    },
    [500, setContent]
  )

  const [loading, setLoading] = useState(false)

  function GetURLParameter() {
    let sPageURL = window.location.href
    const queryString = new URL(sPageURL).search;
    const searchParams = new URLSearchParams(queryString);
    const code = searchParams.get('code');
    return code
  }

  const handleResponse = async (data) => {
    const res = await fetch("https://us-central1-beyouid200.cloudfunctions.net/uploadMessages?getlinkdeInData=" + '1', {
      method: 'POST',
      body: JSON.stringify({
        data: data
      })
    })
    if (res.status !== 200) {
      console.log(res.status)
      throw "Error"
    }
    const ures = await res.json()
    if (ures.error) {
      throw ures.error
    }
    console.log('ressss===>>>>>>>>>>>', ures)
    localStorage.setItem('name', JSON.stringify(ures.fullName))
    localStorage.setItem('imagebase64', JSON.stringify(ures.imageBase64))
    localStorage.setItem('email', JSON.stringify(ures.email))
    localStorage.setItem('socialType', JSON.stringify("linkdeIn"))
    return ures
  };
  const getUserData = async () => {
    let url_id = GetURLParameter('code')
    const messa = localStorage.getItem('message')
    setType(messa)
    if (url_id) {
      setLoading(true)
      try {
        await handleResponse(url_id)
        setLoading(false)
      } catch (error) {
        console.log(error)
        history.push('/social-login')
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getUserData()
  }, [])


  const uploadData = async () => {
    if (content.length == 0) return;

    localStorage.setItem('messageContent', content)

    history.push('/SubmitMessageln')
  }

  if (loading) return <ComponentSpinner />


  return (
    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <Card className='mb-0'>
          <CardBody>
            <LogoHeadr />
            <div className="title mb-3">
              Leave your message
            </div>
            <div>
              <Input
                name='text'
                value={content}
                type='textarea'
                id='exampleText'
                placeholder='Type...'
                style={{
                  minHeight: '180px',
                  resize: 'none',
                  borderRadius: '5px',
                  width: '100%',
                  borderWidth: '1px',
                  padding: 4
                }}
                onChange={(event) => setFormattedContent(event.target.value)}
                /*eslint-disable */
                invalid={content.length === 0 ? true : false}
              />
              <span className="grey_regular_16 mb-6" style={{ color: 'black', fontFamily: 18 }}>
                {`${content.length}/500 characters`}
              </span>

            </div>
            <button className="btn" onClick={uploadData}>
              <span className="title" style={{ fontSize: 16, color: 'white' }}>Continue</span>
            </button>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default LeaveMessageln
