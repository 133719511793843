import React, { useEffect, useState } from 'react';
import { G_SERVER_URL_FLAGPOST, G_SERVER_URL_IMPRESSIONS } from '../utils/global';

const FlagComponent = () => {
     const [reportDone, setReportDone] = useState(false);
     const [showConfirmation, setShowConfirmation] = useState(false);
     const [showAlert, setShowAlert] = useState(false);

     const handleFlagClick = () => {
          setShowConfirmation(true);
     };

     const handleYesClick = async () => {
          setShowConfirmation(false);
          setShowAlert(true);
          setReportDone(true)
          const url_id = localStorage.getItem('postId');
          try {
               const res = await fetch(G_SERVER_URL_FLAGPOST + url_id)
               if (res.status !== 200) {
                    console.log("Error: non-200 status", res.status)
                    return
               }
               localStorage.setItem('reportDone',url_id)
               const resp = await res.json()
               console.log(resp)
          } catch (error) {
               console.log(error)
          }

          // Automatically hide the alert after 3 seconds
          setTimeout(() => {
               setShowAlert(false);
          }, 3000);
     };

     const handleCancelClick = () => {
          setShowConfirmation(false);
     };

     useEffect(()=>{
          const url_id = localStorage.getItem('postId');
          const usern = localStorage.getItem('reportDone')
          setReportDone(usern&&usern==url_id?true:false)
     },[])


     return (
          <div>
               {
               !reportDone&&
               <div
               style={{ cursor: 'pointer', marginRight: '10px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
               onClick={handleFlagClick}
               >
                    <img src='images/flag.png' style={{ width: '25px', height: '25px' }} />
                    <h5 className='grey_bold_18' style={{ color: '#9C5EFF', }}>Report</h5>
               </div>
               }

               {showConfirmation && (
                    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', width: '70%', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: '9999' }}>
                         <p style={{ marginBottom: '15px' }}>Are you sure you want to flag this post?</p>
                         <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                              <button style={{ padding: '10px', background: '#4CAF50', width: '100px', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }} onClick={handleYesClick}>Yes</button>
                              <button style={{ padding: '10px', background: '#ccc', width: '100px', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }} onClick={handleCancelClick}>Cancel</button>
                         </div>
                    </div>
               )}


               {showAlert && (
                    <div style={{ position: 'fixed', top: '10px', right: '10px', padding: '15px', border: '1px solid #4CAF50', backgroundColor: '#4CAF50', color: '#fff', zIndex: '9999', borderRadius: '10px' }}>
                         <p>Thanks for letting us know!</p>
                    </div>
               )}
          </div>
     );
};

export default FlagComponent;
