// ** React Imports
import { useState, useCallback, useEffect } from 'react'

// ** React Imports
import { useHistory } from 'react-router-dom'
import { Buffer } from "buffer";

// ** Reactstrap Imports
import { Card, CardBody, Input } from 'reactstrap'
import ComponentSpinner from '../components/ComponentSpinner'
import LogoHeadr from '../components/LogoHeadr'
import { storage } from './firebase'


const SubmitMessageln = () => {
  //  hook
  const history = useHistory()
  // ** State
  const [type, setType] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const messa = localStorage.getItem('message')
    const emal = JSON.parse(localStorage.getItem('email'))
    setEmail(emal)
    setType(messa)
  }, [])


  const uploadData = async () => {
    if (!email) return;
    setLoading(true)
    const imagebase64 = JSON.parse(localStorage.getItem('imagebase64'))
    const socialType = JSON.parse(localStorage.getItem('socialType'))
    const messageContent = localStorage.getItem('messageContent')
    const uid = localStorage.getItem('id')
    const url_id = localStorage.getItem('url_id')
    const share = localStorage.getItem('share');
    const name = JSON.parse(localStorage.getItem('name'))
    const messa = localStorage.getItem('message')
    const pdf = localStorage.getItem('pdf')

    try {
      const file = imagebase64.split(";base64,").pop();
      const buffer = Buffer.from(file, "base64");
      const blob = new Blob([buffer], { type: "image/jpeg" });

      const imageFilleName = `testimonials/${uid}_${new Date().toISOString().replace(/[.:-]+/g, "_")}`;

      const storageRef = storage.ref(imageFilleName);
      await storageRef.put(blob);
      const url = await storageRef.getDownloadURL();
      console.log("urrlll==>>", url)
      const res = await fetch("https://us-central1-beyouid200.cloudfunctions.net/uploadMessages?uploadData=" + '12', {
        method: 'POST',
        body: JSON.stringify({
          uid: messa == 'permissions' ? url_id : uid,
          name: name,
          imagebase64: url,
          socialType: socialType,
          message: messageContent,
          type: messa,
          email: email,
          pdf: messa == 'permissions' ? pdf : "",
          share:share
        }),
        mode: 'no-cors'
      })
      const ures = res
      console.log("errrrrrrrr", ures)
      setLoading(false)
      history.push('/Thanks')
    } catch (error) {
      console.log('11err=>>', error)
      setLoading(false)
    }
  }

  if (loading) return <ComponentSpinner />


  return (
    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <Card className='mb-0'>
          <CardBody>
            <LogoHeadr />
            <div className="title">
              Email verified
            </div>
            <div className="grey_regular_16 mb-6" style={{ color: 'black', fontFamily: 18 }}>
              {type == 'permissions' ?
                "Please provide your email address for document delivery upon user approval. Once the user's request is approved, we will send the documents to this email address."
                :
                "Please provide your email address for communication purposes. After the user views your message and replies, you will receive their response in your provided email address. Additionally, you can communicate with the user through BeYouID app using this email."}
            </div>
            <Input
              name='text'
              value={email}
              type='email'
              id='exampleText'
              placeholder='john@example.com'
              style={{
                borderRadius: '5px',
                width: '100%',
                borderWidth: '1px',
                padding: 4,
                marginBottom: 10
              }}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button className="btn" onClick={uploadData}>
              <span className="title" style={{ fontSize: 16, color: 'white' }}>{type == 'permissions' ? "Send Request" : "Send Message"}</span>
            </button>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default SubmitMessageln
