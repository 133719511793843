// ** React Imports
import { Link, useHistory } from 'react-router-dom'

// **  reac feather icons
import { ArrowLeft } from 'react-feather'

const LogoHeadr = ({back=true}) => {
  const history = useHistory()
  return (
    <div className='flex mb-2 mt-1' style={{ justifyContent: 'space-between' }}>
      {
        back?
        <ArrowLeft
        className='IconButton cursor-pointer'
        onClick={() => history.goBack()}
        />:
        <div/>
      }
      <Link
        className='brand-logo m-0  '
        to='/'
        onClick={(e) => e.preventDefault()}
      >
        <img src={'images/BeyouIdLogo.png'} width={40} alt='Not authorized page' />
      </Link>
    </div>
  )
}

export default LogoHeadr
